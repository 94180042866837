@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/helpers/align.scss";

.tabs {
  padding: 0 20px;
  border-bottom: 1px solid $baseTextColor;

  .tab {
    display: inline-flex;
    align-items: baseline;
    border-color: $baseTextColor;
    padding: 8px 20px;
    color: $secondary2Color;
    background: #424242;

    div {
      background: $ColorGrayscaleGray1 !important;
    }
  }

  .isTabActive {
    color: $ColorGrayscaleWhite;
    background: $ColorGrayscaleBlack;
    border-bottom-color: transparent;
    font-weight: 500;
  }
}
