@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.wrapper {
  padding: 20px;
  background: $ColorGrayscaleGray6;
  margin-bottom: 20px;

  @include mqMaxWidth($screenS) {
    margin-bottom: 10px;
  }

  .filesList {
    list-style: none;
    margin: 0;
    padding: 0;

    .fileItem {
      display: flex;
      font-size: 13px;
      color: $ColorGrayscaleBlack;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .icon {
        margin-right: 10px;
        width: 14px;
        height: 14px;
      }
    }
  }

  .count {
    margin-top: 10px;
    color: $ColorGrayscaleGray1;
  }
}

