@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.redactModal {
  :global {
    .modalDialog {
      max-width: 600px;

      .modalBody {
        padding-bottom: 25px;
      }

      @include mqMaxWidth($screenS) {
        .modalHeader {
          padding: 10px 10px 0;
        }

        .modalCloseBtn {
          top: 10px;
          right: 10px;
        }

        .modalBody {
          padding: 10px 10px 15px;
        }

        .modalFooter {
          padding: 0 10px 10px;
        }
      }
    }
  }

  .note {
    font-size: 13px;
    line-height: 18px;
    color: $ColorGrayscaleGray1;
    margin: 0 0 20px;

    @include mqMaxWidth($screenS) {
      & {
        margin-bottom: 10px;
      }
    }
  }
}

.spinnerOverlay {
  width: 100%;
  height: 100%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.keywordInput {
  height: 40px;
}

.footer {
  margin-top: 30px;
}
