.sidebar {
  position: fixed;
  top: 50px;
  right: 0;
  width: 300px;
  flex: 0 0 255px;
  background: #2d2d2d;
  transform: translateX(0);
  transition: all 0.5s;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;

  &.isHidden {
    opacity: 0;
    visibility: hidden;
    transform: translateX(100%);
  }
}
