@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/helpers/align.scss";

.zeroContainer {
  @extend .flexColumnCenter;

  height: 100%;
  font-size: 12px;
  line-height: 17px;
  color: $ColorGrayscaleGray2;
  text-align: center;

  .zeroText {
    width: 150px;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  .icon {
    fill: $secondary3Color;
    width: 73px;
    height: 50px;
  }
}
