@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/helpers/align.scss";

.searchWrapper {
  position: relative;

  .inputSearch {
    background: rgba(0, 0, 0, 0.4);
    min-height: 36px;
    color: $ColorGrayscaleWhite;
    border: 0;

    &:focus {
      background: rgba(0, 0, 0, 0.7);
    }
  }

  .searchIcon {
    > svg {
      fill: $gray4;
    }
  }

  .errorText {
    position: absolute;
    top: 38px;
    left: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.12px;
    color: $ColorGrayscaleGray2;
  }
}
