@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/helpers/align.scss";

.groupWrapper {
  @extend .flexCenter;
  height: 210px;

  .checkboxList {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    max-height: 210px;
  }

  @include mqMaxWidth($screenS) {
    flex-wrap: nowrap;
    max-height: 100%;
  }

  .checkboxItem {
    display: flex;
    align-items: center;
    gap: 5px;

    .icon {
      width: 12px;
      height: 12px;
      fill: $gray2;

      &:hover {
        fill: $ColorGrayscaleBlack;
      }
    }
  }

  .tooltipContainer {
    display: flex;
    align-items: center;
  }

  .betaLabel {
    color: $ColorGrayscaleWhite;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding: 3px;
    background: $secondary2Color;
    border-radius: 2px;
  }
}
