@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.confirmationModal {
  z-index: 5;

  :global(.modalDialog) {
    max-width: 450px;
  }
}

.removeButton {
  background: $cancelColor;

  &:hover {
    background-color: #a83737;
  }
}
