@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.documentPreviewer {
  z-index: 5;
}

.previewNotAvailableModal {
  :global(.modalDialog) {
    max-width: 450px;
  }
}

.securityMobileNotification {
  font-size: 12px;
  line-height: normal;
  min-width: 285px;
}

.separator {
  background: $btnDefaultColor;
  margin: 5px 0;
  height: 1px;
}

.withRedactionMode div[data-test=documentViewerContent] {
  padding-right: 300px;
}

.textToolActive div[data-test=textLayerWrapper] {
  z-index: 4 !important;
}
