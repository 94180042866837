@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.panelGroup {
  box-shadow: none;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  margin-bottom: 30px;
  z-index: -1;
  overflow: auto;

  &:hover {
    box-shadow: none;
  }

  .categoryList {
    margin: 0;
    padding: 0;
    list-style: none;

    &:last-child {
      margin-bottom: 15px;
    }
  }

  .panelGroupItem {
    border-top: 1px solid #1f1f1f;
    box-shadow: none;
    padding: 0 20px;
    margin: 0;

    &:first-of-type {
      margin-top: 10px;
    }

    &:last-of-type {
      border-bottom: 1px solid #1f1f1f;
    }

    &:hover {
      box-shadow: none;
    }

    .panelGroupHeader {
      position: relative;
      background-color: transparent;
      padding: 20px 0;
      color: $secondary3Color;
      cursor: pointer;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 7px;
      text-transform: uppercase;
      letter-spacing: 1px;

      &:hover {
        color: $ColorGrayscaleWhite;
      }

      .panelGroupHeaderArrow {
        right: 0;
      }
    }

    .panelGroupHeaderArrowIcon {
      border: 0;

      &:hover {
        box-shadow: none;
        background-color: transparent;
      }
    }

    .panelGroupArrowIcon {
      padding-top: 3px;
      transition: none;
      right: 5px;

      i {
        fill: $secondary3Color;
        width: 7.5px;
        height: 4.167px;
      }
    }

    .content {
      padding: 0;
      background-color: transparent;
    }
  }
}

.arrowWrapper {
  width: 0;

  &:hover {
    background-color: transparent;

    i {
      fill: $secondary3Color;
    }
  }
}
